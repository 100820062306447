import { Flex, Text, Divider, Center, Link, Button } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { sendNewEmailVerification } from "./index";
import { sendEmailVerification } from "firebase/auth";

const Create = () => {
  const navigator = useNavigate();
  const location = useLocation();

  if (!location.state || !location.state.email || !location.state.password) {
    // Handle the case where location.state is not defined or missing properties
    // You might want to redirect the user or show an error message
    navigator("/");
    return;
  }

  return (
    <Flex p={6} width={"100%"}>
      <Flex
        color={"white"}
        flexDirection={"column"}
        gap={"12px"}
        width={"100%"}
      >
        <Text fontWeight={"bold"} fontSize={"18px"}>
          Verifiera konto
        </Text>
        <Text fontSize={"14px"}>
          Ett verifieringsmeddelande har skickats till e-postadressen{" "}
          {location.state.email}.
        </Text>

        <Text fontSize={"14px"}>
          Om du inte fått ett mail verifieringsmeddelande, begär en ny genom att{" "}
          <Link
            fontWeight={"bold"}
            onClick={async () => {
              const verify = await sendNewEmailVerification(
                location.state.email,
                location.state.password
              );
              if (verify.status === 200) {
                toast.success(
                  `Ett verifieringsmeddelande har skickats till e-postadressen: ${location.state.email}`
                );
              } else {
                toast.error(verify.error);
              }
            }}
          >
            klicka här.
          </Link>
        </Text>
        <Center w={["100%"]} h={["40px", "40px", "auto"]}>
          <Divider />
        </Center>

        <Flex alignItems={"center"} gap={2} color={"#718096"} fontSize={"12px"}>
          <Text>Redan veriferad? </Text>

          <Link
            color={"white"}
            onClick={() =>
              navigator("/login", { state: { removeGoogle: true } })
            }
            fontWeight={"bold"}
          >
            Logga in här
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Create;
