import React, { useState, useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { Box, Text, Flex } from "@chakra-ui/react";
import { UserContext } from "../../context/index.js";
import { LockIcon } from "@chakra-ui/icons";

import { Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import axios from "axios";

const SelectComponent = ({
  label,
  qrSettings,
  setqrSettings,
  toolTipText,
  toolTip,
  readOnly,
}) => {
  const proxytargetUrl = process.env.REACT_APP_API_ENDPOINT + "/";
  const [userInput, setUserInput] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [state] = useContext(UserContext);
  const [userTags, setUserTags] = useState([]);
  const [loadedTags, setLoadedTags] = useState(false);

  const DeleteOption = ({ label, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async (e) => {
      e.stopPropagation();
      setIsLoading(true);

      try {
        await removeTagFromUser(label);
        const updatedUserTags = userTags.filter((tag) => tag.label !== label);
        setUserTags(updatedUserTags);
      } catch (error) {
        console.error("Error deleting tag:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const removeTagFromUser = async (tag) => {
      try {
        await axios.delete(proxytargetUrl + "deleteTag/" + tag, {
          headers: { Authorization: await state.user.getIdToken() },
        });
      } catch (error) {
        console.error("Error deleting tag:", error);
        throw error;
      }
    };

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {label}
          <button onClick={handleDelete} disabled={isLoading}>
            {isLoading ? "Ta bort tag..." : "X"}
          </button>
        </div>
      </components.Option>
    );
  };

  useEffect(() => {
    if (loadedTags) return;

    setSelectedOptions(qrSettings.tags);
    setLoadedTags(true);
  }, [qrSettings, loadedTags]);

  useEffect(() => {
    GetTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetTag = async () => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/getTags", {
        headers: { Authorization: await state.user.getIdToken() },
      })
      .then((response) => {
        const transformData = response.data.data.map((item, index) => ({
          value: item.tag,
          label: item.tag,
          color: item.color,
          key: item.tag + index,
        }));
        setUserTags(transformData);
      });
  };

  const AddTag = async (tag) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/createTag`,
        { tag: tag.value, color: "None" },
        {
          headers: { Authorization: await state.user.getIdToken() },
        }
      );
    } catch (error) {
      // Handle errors here
      console.error("Error creating tag:", error);
    }
  };

  const handleInputChange = async (newValue) => {
    setUserInput(newValue);
  };

  const handleKeyDown = (event) => {
    if (
      (event.key === "Enter" || event.which === 13) &&
      userInput.trim() !== ""
    ) {
      const newOption = {
        value: userInput,
        label: userInput,
        color: "none",
      };
      const containsDuplicate = selectedOptions.some(
        (child) => child.value === newOption.value
      );
      if (!containsDuplicate) {
        setSelectedOptions([...selectedOptions, newOption]);
        AddTag(newOption);
      }
      setUserInput("");
    }
  };

  useEffect(() => {
    if (readOnly) return;
    userTags.map((item) => ({
      tag: item.value,
      color: "none",
    }));
    setqrSettings({ ...qrSettings, tags: selectedOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, setqrSettings, userTags]);

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        Ingen tagg hittades, lägg till genom klicka på enter.
      </components.NoOptionsMessage>
    );
  };

  const SubmitOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          asd
        </div>
      </components.Option>
    );
  };
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Flex flexDirection={"column"}>
      <Flex alignItems={"center"} padding={"10px 0px"}>
        {/* {!state.stripeRole && (
          <Tooltip
            label={"Tillgängligt för enbart betalande kunder"}
            fontSize="xs"
            p={"10px"}
            bg="#292929"
          >
            <LockIcon w={3} h={3} marginRight={2} color="#292929" />
          </Tooltip>
        )}
        */}
        <Text color={"#292929"} fontWeight={"semibold"}>
          {label}
        </Text>
        {toolTip && (
          <Box marginLeft={"auto"}>
            <Tooltip
              label={toolTipText}
              fontSize="xs"
              p={"10px"}
              bg="#292929"
              isOpen={isLabelOpen}
            >
              <InfoIcon
                color={"#292929"}
                onClick={() => setIsLabelOpen(!isLabelOpen)}
                onMouseLeave={() => setIsLabelOpen(false)}
                onMouseOver={() => setIsLabelOpen(true)}
              />
            </Tooltip>
          </Box>
        )}
      </Flex>
      <Flex gap={"20px"} flexDirection={"column"}>
        <Flex gap={"20px"}>
          <Box w={"100%"}>
            <Select
              className="selector"
              /* isDisabled={readOnly || !state.stripeRole ? true : false} */
              isDisabled={readOnly}
              inputValue={userInput}
              onInputChange={handleInputChange}
              options={userTags}
              isMulti
              placeholder="Lägg till..."
              value={selectedOptions}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              isCreatable
              isSearchable={true}
              formatCreateLabel={(inputValue) => `Lägg till "${inputValue}"`}
              components={{
                Option: (props) => <DeleteOption {...props} />,
                NoOptionsMessage,
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SelectComponent;
