import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Grid,
  Flex,
  Button,
  Box,
  Text,
  FormControl,
  Spinner,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";
import { UserContext } from "../../../../context/index.js";
import { editQRCode as editQRCodeCall } from "../../../../calls";
import { areAllValuesSet } from "../../../../functions/areAllValuesSet";
import { toast } from "react-toastify";
import InputComponent, {
  InputComponentTextField,
} from "../../../../components/Widgets/InputComponent";
import checkIfValidEmails from "../../../../functions/validEmails.js";

import "./alert.css";

const AlertsQR = ({ qrSettings, setqrSettings }) => {
  const [state] = useContext(UserContext);
  const [alert, setAlert] = useState({
    active: qrSettings.alert.active || false,
    emails: qrSettings.alert.emails.length > 0 ? qrSettings.alert.emails : [""],
    numbers:
      qrSettings.alert.numbers.length > 0 ? qrSettings.alert.numbers : [""],
    description: qrSettings.alert.description || "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingNotifaction, setLoadingNotifcation] = useState(false);

  const emailRefs = useRef([]);
  const numberRefs = useRef([]);

  const handleAdd = () => {
    const newAlerts = [...alert.emails, ""];
    if (newAlerts.length > 5) {
      toast.error("Du har nått det maximala antalet e-postadresser.");
      return;
    }
    setAlert({ ...alert, emails: newAlerts });
  };

  const handleEmailChange = (index, value) => {
    const updatedAlert = { ...alert };
    updatedAlert.emails[index] = value;
    setAlert(updatedAlert);
  };

  const handleNumberChange = (index, value) => {
    const copyArr = [...alert.numbers];
    copyArr[index] = value;
    setAlert({ ...alert, numbers: copyArr });
  };

  const saveAlert = async () => {
    setLoading(true);
    if (JSON.stringify(qrSettings.alert) === JSON.stringify(alert)) {
      toast.success("Du har sparat notification inställningar.");
      setTimeout(() => setLoading(false), 1000);
      return;
    }
    if (!areAllValuesSet(qrSettings)) return;

    const editCodeResponse = await editQRCodeCall(
      () => state.user.getIdToken(),
      {
        ...qrSettings,
        alert: { ...alert, emails: alert.emails.filter((item) => item !== "") },
      }
    );
    if (editCodeResponse.status === 200) {
      setqrSettings({ ...qrSettings, ...editCodeResponse.data.data });
      toast.success("Du har sparat notification inställningar");
    } else {
      toast.error("Något gick fel och QR-koden updaterades inte.");
    }
    setLoading(false);
  };

  const startNotification = async () => {
    setLoadingNotifcation(true);
    if (!areAllValuesSet(qrSettings)) return;

    const editCodeResponse = await editQRCodeCall(
      () => state.user.getIdToken(),
      {
        ...qrSettings,
        alert: { ...alert, active: !alert.active },
      }
    );
    if (editCodeResponse.status === 200) {
      setqrSettings({ ...qrSettings, ...editCodeResponse.data.data });
      setAlert({ ...alert, active: !alert.active });
      toast.success(
        !alert.active
          ? "Du har startat notification"
          : "Du har stoppat notification"
      );
    } else {
      toast.error("Något gick fel och QR-koden updaterades inte.");
    }
    setLoadingNotifcation(false);
  };

  useEffect(() => {
    emailRefs.current = emailRefs.current.slice(0, alert.emails.length);
    numberRefs.current = numberRefs.current.slice(0, alert.numbers.length);
  }, [alert.emails.length, alert.numbers.length]);

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      gridTemplateColumns={"1fr 1fr"}
      gap={"20px"}
      position={"relative"}
    >
      <Flex flexDirection={"column"} gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            <Flex
              gap={"14px"}
              width={"100%"}
              flexDirection={"column"}
              marginBottom={"auto"}
              position={"relative"}
            >
              {alert.active && (
                <Tooltip
                  label={"Denna indikerar att dina notiser är aktiva."}
                  fontSize="xs"
                  p={"10px"}
                  bg="#292929"
                >
                  <Box
                    position={"absolute"}
                    right={0}
                    top={0}
                    marginLeft={"auto"}
                    bg="#062c8d"
                    width={"10px"}
                    height={"10px"}
                    rounded={"100%"}
                    animation="pulse 2s infinite"
                    boxShadow="0 0 2px 2px #B8CCFF"
                  ></Box>
                </Tooltip>
              )}
              <Box>
                <Text fontWeight={"semibold"} fontSize={"20px"}>
                  Smart-Pling!
                </Text>
                <Text fontSize={"16px"} fontStyle={"italic"}>
                  Notifikationer vid skanning.
                </Text>
              </Box>
              <Text fontSize={"14px"}>
                Realtidsnotis skickas varje gång din QR-kod skannas. För
                närvarande skickas notiserna via e-post. Du kan välja vilka
                e-postadresser som ska ta emot notisen. Observera att din
                e-postadress är synlig för mottagaren.
              </Text>
              <Text fontSize={"14px"}>
                För att minska risken för spammail har vi implementerat en
                begränsning där notiser endast kan skickas en gång var femte
                minut per QR-kod.
              </Text>
            </Flex>
          }
        />

        <WidgetWrapper
          child={
            <Flex flexDirection={"column"} w={"100%"}>
              <FormControl>
                {alert.emails.map((email, index) => (
                  <InputComponent
                    isError={!checkIfValidEmails([email])}
                    type={"emails"}
                    toolTip={index === 0 && true}
                    toolTipText={
                      "Här kan du lägga till emails för varje som ska få notis när QR-kod skannas"
                    }
                    label={index === 0 && "Emails"}
                    placeholder={"email@email.com"}
                    index={index}
                    key={index}
                    value={email}
                    setState={(newVal) => handleEmailChange(index, newVal)}
                    ref={(el) => (emailRefs.current[index] = el)}
                    checkBox={
                      index !== 0 && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            const newEmails = [...alert.emails]; // Create a copy of the emails array
                            newEmails.splice(index, 1); // Remove the email at the given index
                            setAlert({ ...alert, emails: newEmails });
                          }}
                        >
                          x
                        </Button>
                      )
                    }
                  />
                ))}
              </FormControl>
              <Flex>
                <Button
                  marginTop={"10px"}
                  marginRight={"auto"}
                  onClick={() => handleAdd("numbers")}
                >
                  +
                </Button>
                <Text marginTop={"5px"} fontSize={"8px"} fontWeight={"bold"}>
                  ( {alert.emails.length} / 5 )
                </Text>
              </Flex>
            </Flex>
          }
        />
        <WidgetWrapper
          closeDown={true}
          closeDownText="Notiser via SMS kommer snart."
          child={
            <Flex flexDirection={"column"} w={"100%"} marginBottom={"auto"}>
              <FormControl>
                {alert.numbers.map((key, index) => (
                  <InputComponent
                    type={"number"}
                    label={index === 0 && "SMS"}
                    placeholder={"+076123123123"}
                    index={index}
                    key={index}
                    value={key}
                    setState={(newVal) => handleNumberChange(index, newVal)}
                    ref={(el) => (numberRefs.current[index] = el)}
                  />
                ))}
              </FormControl>
              <Flex>
                <Button
                  marginTop={"10px"}
                  marginRight={"auto"}
                  onClick={() => handleAdd("numbers")}
                >
                  +
                </Button>
                <Text marginTop={"5px"} fontSize={"8px"} fontWeight={"bold"}>
                  ( {alert.emails.length} / 5 )
                </Text>
              </Flex>
            </Flex>
          }
        />
      </Flex>
      <Flex flexDirection={"column"} gap={"20px"} marginBottom={"auto"}>
        {/* Text Field Widget */}

        <WidgetWrapper
          child={
            <Flex width={"100%"} flexDirection={"column"}>
              <InputComponentTextField
                toolTip={true}
                toolTipText={
                  "Här kan du lägga till extra medelande i dina notiser ."
                }
                placeholder={"Notis för att öppna dörren på 3 våningen..."}
                label={"Meddelande till mottagaren"}
                value={alert.description}
                type={"text"}
                setState={(newVal) =>
                  setAlert({ ...alert, description: newVal })
                }
              />
            </Flex>
          }
        />
        {/* Button Block */}
        <Flex width={"100%"} flexDirection={"column"} gap={"20px"}>
          <Button
            w={"full"}
            p={8}
            bg={"#5138EE"}
            fontSize={"18px"}
            color={"white"}
            fontWeight={"bold"}
            onClick={!loading ? saveAlert : () => {}}
            isDisabled={!checkIfValidEmails(alert.emails)}
          >
            {!loading ? "Spara" : <Spinner></Spinner>}
          </Button>
          <Button
            bg={alert.active ? "#062c8d" : "#5138ee"}
            color={"white"}
            isDisabled={!qrSettings.alert.emails[0]}
            onClick={!loadingNotifaction ? startNotification : () => {}}
          >
            {loadingNotifaction ? (
              <Spinner></Spinner>
            ) : alert.active ? (
              "Pausa Notifikation"
            ) : (
              "Starta Notifikation"
            )}
          </Button>
          <WidgetWrapper
            child={
              <Flex flexDirection={"column"} gap={"14px"}>
                <Text
                  fontWeight={"bold"}
                  fontStyle={"italic"}
                  fontSize={"12px"}
                >
                  Observera att denna funktion är i testläge och kan ändras över
                  tid.
                </Text>
                <Text fontStyle={"italic"} fontSize={"12px"}>
                  Vi välkomnar gärna all feedback! Du kan skicka oss ett mail på
                  qr@dinqr.se eller använda vårt{" "}
                  <Link href="/support/kontakt">kontaktformulär</Link> för att
                  skriva till oss.
                </Text>
              </Flex>
            }
          />
        </Flex>
      </Flex>
    </Grid>
  );
};

export default AlertsQR;
