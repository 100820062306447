import { Flex, Button, Box, Divider } from "@chakra-ui/react";
import { useState } from "react";
import QRStyle from "./QRstyle";
import QRlogo from "./QRlogo";
import DesignTemplateSelector from "./DesignTemplateSelector";

const menu = [{ text: "Design" }, { text: "Logotyp" }];

const DesignConstructor = ({ setState, value, setLoadImage }) => {
  const [column, setColumn] = useState(0);

  return (
    <Flex flexDirection={"column"} height={"100%"} width={"100%"}>
      <Flex
        gap={5}
        flexDirection={["column", "column", "row"]}
        marginBottom={"10px"}
      >
        {menu.map((child, index) => {
          return (
            <Flex key={child.text + index}>
              <Box>
                <Button
                  bg={""}
                  _hover={"none"}
                  _active={"none"}
                  color={column === index ? "#5138ec" : "#292929"}
                  onClick={() => {
                    setColumn(index);
                  }}
                >
                  {child.text}
                </Button>
              </Box>
              {index === 0 && (
                <Flex marginY={"auto"} display={["none", "none", "flex"]}>
                  <Divider orientation="vertical" h={"20px"}></Divider>
                </Flex>
              )}
            </Flex>
          );
        })}
        <DesignTemplateSelector
          setLoadImage={setLoadImage}
          setState={setState}
          value={value}
        />
      </Flex>
      {column === 0 && <QRStyle setState={setState} value={value} />}
      {column === 1 && (
        <QRlogo column={column} setState={setState} value={value} />
      )}
    </Flex>
  );
};

export default DesignConstructor;
