import { Box, Grid, Flex, Button, Text, Link, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { useEffect, useContext, useState } from "react";
import DesignConstructor from "../../../components/DesignQR";
import { UserContext } from "../../../context/index.js";
import { useNavigate } from "react-router-dom";
import { ensureProtocol } from "../../../functions/ensureProtocolUrl.js";

import SelectionWidget from "../../../components/Widgets/SelectionWidget";

import {
  generateQRID,
  createQRCode,
  checkUserAmountOfQRCodes,
  createDesignTemplate,
} from "../../../calls";
import QrGenerator from "../../../components/QrGenerator";
import { areAllValuesSet } from "../../../functions/areAllValuesSet";
import { toast } from "react-toastify";

const CreateQR = () => {
  const navigation = useNavigate();
  const [state] = useContext(UserContext);
  const [checkQRcount, setCheckQRCount] = useState();
  const [designName, setDesignName] = useState("");
  const [showDesignTemplete, setShowDesignTemplete] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [qrSettings, setqrSettings] = useState({
    targetUrl: "",
    qrName: "",
    color: "#000000",
    background: "#FEFEFE",
    eyeRadius: 0,
    qrStyle: "Squares",
    ecLevel: "L",
    qrID: "",
    tags: [],
    alert: false,
  });

  const fetchDataPromise = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (qrSettings.qrID) {
          resolve({ check: true });
        } else {
          await checkUserAmountOfQRCodes(() => state.user.getIdToken());
          setCheckQRCount({ check: true });
          resolve({ check: true });
        }
      } catch (error) {
        setCheckQRCount({ data: error.response.data, check: false });
        reject(error);
      }

      try {
        if (!qrSettings.qrID) {
          const qrID = await generateQRID(() => state.user.getIdToken());
          setqrSettings((prevSettings) => ({ ...prevSettings, qrID: qrID }));
          resolve(qrID);
        }
      } catch (error) {
        // Handle error if needed
        console.error(error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchDataPromise()
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrSettings.qrID, state.user]);

  if (loading) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"}></Spinner>
      </Flex>
    );
  }
  if (!checkQRcount) {
    return;
  }

  if (!checkQRcount.check) {
    return (
      <Grid
        display={["grid"]}
        flexDirection={"column"}
        gridTemplateColumns={"1fr"}
        paddingBottom={"20px"}
        gap={"20px"}
        height={"100%"}
      >
        <WidgetWrapper
          extraTextComponoent={
            <Box>
              <Flex
                flexDirection={"column"}
                alignItems={"center"}
                textAlign={"center"}
                padding={"0px 20px"}
              >
                <Text>
                  Du har skapat det maximala antalet QR-koder{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ({checkQRcount.data.data.maximumCodes})
                  </span>{" "}
                  för din betalningsplan:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {state.stripeRole ? state.stripeRole : "Gratis konto"}
                  </span>{" "}
                </Text>
                <Text>
                  Behöver du fler QR-koder? Kontakta oss via e-post på
                  qr@dinqr.se så ser vi till att hjälpa dig.
                </Text>
              </Flex>
            </Box>
          }
          qrLimit={true}
          child={
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            ></Flex>
          }
        />
      </Grid>
    );
  }

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      gridTemplateColumns={"1fr 1fr "}
      paddingBottom={"20px"}
      gap={"20px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          blockForReadersOnly={true}
          callToAction={true}
          child={
            <Box w={"100%"}>
              <InputComponent
                toolTip={true}
                toolTipText={
                  "Här har du möjligheten att ge din QR-kod ett namn för att underlätta sökningen i systemet."
                }
                placeholder={"ex. Reklamblad västkusten"}
                label={"QR Namn*"}
                setState={setqrSettings}
                value={qrSettings}
                keyValue={"qrName"}
                type={"text"}
              />

              <InputComponent
                toolTip={true}
                toolTipText={
                  "Denna länk är destinationen dit din QR-kod kommer att leda till efter att den har scannats."
                }
                placeholder={"https://hemsida.se"}
                label={"Länk*"}
                setState={setqrSettings}
                value={qrSettings}
                keyValue={"targetUrl"}
                type={"url"}
              />
              <SelectionWidget
                toolTip={true}
                toolTipText={
                  "Här har du möjlighet att välja olika taggar för att göra det enklare att sortera och hantera dina QR-koder."
                }
                payedFeature={false}
                label={"Taggar"}
                qrSettings={qrSettings}
                setqrSettings={setqrSettings}
              />
            </Box>
          }
        />
        <WidgetWrapper
          callToAction={true}
          payedFeature={false}
          blockForReadersOnly={true}
          child={
            <DesignConstructor
              setLoadImage={setLoadImage}
              setState={setqrSettings}
              value={qrSettings}
            />
          }
        />
      </Grid>

      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          center={true}
          blockForReadersOnly={true}
          child={
            <Flex
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              position={"relative"}
            >
              {loadImage ? (
                <Flex alignItems={"center"} height={"230px"}>
                  <Spinner></Spinner>
                </Flex>
              ) : (
                <QrGenerator
                  loadImage={loadImage}
                  qrSettings={qrSettings}
                  setqrSettings={setqrSettings}
                />
              )}
            </Flex>
          }
        />
        <Flex flexDirection={"column"}>
          <Button
            w={"full"}
            p={8}
            bg={areAllValuesSet(qrSettings) ? "#5138EE" : "#A398EA"}
            fontSize={"18px"}
            color={"white"}
            fontWeight={"bold"}
            isDisabled={!designName && showDesignTemplete}
            onClick={async () => {
              if (!areAllValuesSet(qrSettings)) return;
              //DesignTemplate
              if (designName) {
                try {
                  await createDesignTemplate(() => state.user.getIdToken(), {
                    ...qrSettings,
                    designName: designName,
                  });

                  toast.success(`Du har sparat designmallen: ${designName}`);
                } catch (error) {
                  toast.error("Någon gick fel att spara designmallen");
                }
              }
              //Create QR
              try {
                await createQRCode(() => state.user.getIdToken(), {
                  ...qrSettings,
                  targetUrl: ensureProtocol(qrSettings.targetUrl),
                });
                toast.success(`Din QR-kod ${qrSettings.qrName} har skapats`);
                navigation("/mina-qr/" + qrSettings.qrID);
              } catch (error) {
                toast.error(error.response.data.message);
              }
            }}
          >
            Skapa QR {showDesignTemplete && "och Designmall"}
          </Button>

          {!showDesignTemplete && (
            <Link
              onClick={() => setShowDesignTemplete(true)}
              color={"#292929"}
              marginLeft={"auto"}
              fontSize={"14px"}
              marginTop={"5px"}
            >
              Vill du spara din designmall?
            </Link>
          )}
          <Flex marginTop={"20px"}>
            {showDesignTemplete && (
              <WidgetWrapper
                child={
                  <InputComponent
                    setState={setDesignName}
                    value={designName}
                    underText={
                      "Här kan du välja att spara din design genom att ge din design ett namn. En designmall kan hjälpa dig att göra QR-koder som följer samma design."
                    }
                    placeholder={"Skriv namnet på din designmall..."}
                    label={"Spara din Designmall"}
                  ></InputComponent>
                }
              />
            )}
          </Flex>
          {showDesignTemplete && (
            <Link
              onClick={() => setShowDesignTemplete(false)}
              color={"#292929"}
              marginLeft={"auto"}
              fontSize={"14px"}
              marginTop={"5px"}
            >
              Jag vill inte spara en designmall.
            </Link>
          )}
        </Flex>
      </Grid>
    </Grid>
  );
};

export default CreateQR;
