const Options = [
  {
    value: 7,
    label: "Senaste 7 dagarna",
    defaultValue: { value: "DAY", label: "Dag" },
    interval: [
      { value: "MINUTE", label: "Minut" },
      { value: "HOUR", label: "Timme" },
      { value: "DAY", label: "Dag" },
    ],
  },
  {
    value: 30,
    label: "Senaste 30 dagarna",
    defaultValue: { value: "WEEK", label: "Vecka" },
    interval: [
      { value: "HOUR", label: "Timme" },
      { value: "DAY", label: "Dag" },
    ],
  },
  {
    value: 30 * 6,
    label: "Senaste 6 månaderna",
    defaultValue: { value: "MONTH", label: "Månad" },
    interval: [
      { value: "DAY", label: "Dag" },
      { value: "MONTH", label: "Månad" },
    ],
  },
  {
    value: 365,
    label: "Senaste 12 månaderna",
    defaultValue: { value: "MONTH", label: "Månad" },
    interval: [
      { value: "DAY", label: "Dag" },
      { value: "MONTH", label: "Månad" },
    ],
  },
];

export default Options;
