import { Grid, Flex, Text, Button, Checkbox } from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";

import { UserContext } from "../../../../context/index.js";

import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { deactivateQRCode } from "../../../../calls";

const DeleteQR = ({ qrSettings, setqrSettings }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      width={["100%", "100%", "70%"]}
      gap={"20px"}
    >
      <WidgetWrapper
        child={
          <Flex flexDirection={"column"} gap={3}>
            <Text fontSize={"20px"}>Vill du radera din QR kod?</Text>
            <Text fontSize={"14px"}>
              När du raderar en QR-kod slutar den att fungera direkt och
              kommer inte leda någonstans längre. Det är vikitgt att ta
              bort QR-koden från den fysiska platsen så den inte förvirrar
              användare eller skräpar ner. Tack!
            </Text>
            <Flex flexDirection={["column", "column", "row"]}>
              <Checkbox
                isChecked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              >
                Jag godkänner att min QR-kod raderas.
              </Checkbox>
              <Button
                color={"white"}
                bg={"#ee3838"}
                marginTop={["10px", "10px", "0px"]}
                marginLeft={["0px", "0px", "auto"]}
                isDisabled={!isCheckboxChecked}
                onClick={async () => {
                  const data = await deactivateQRCode(
                    () => state.user.getIdToken(),
                    qrSettings
                  );
                  if (data.status === 200) {
                    navigate("/mina-qr");
                  } else {
                    //Do something else
                  }
                }}
              >
                Ta bort QR-kod
              </Button>
            </Flex>
          </Flex>
        }
      ></WidgetWrapper>
    </Grid>
  );
};

export default DeleteQR;
