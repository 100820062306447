import { Grid, GridItem, Flex, Text, Divider } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import {
  MdOutlineQrCode,
  MdOutlineSafetyCheck,
  MdOutlinePayment,
  MdChatBubbleOutline,
  MdBusiness,
  MdHealthAndSafety,
  MdQuestionMark,
  MdQuestionAnswer,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

const questionSection = [
  {
    title: "Data och säkerhet",
    underTitle: "Läs hur vi hanterar data och säkerhet",
    icon: <MdHealthAndSafety size={50} />,
    openWebsite: process.env.REACT_APP_STATIC_STIE + "/privacy",
  },
  {
    title: "Kontakta Oss",
    underTitle: "Vi hjälper dig!",
    icon: <MdQuestionAnswer size={50} />,
    redirect: "/support/kontakt",
    closeDown: false,
  },
  {
    title: "Komma igång",
    underTitle: "Lär dig att skapa SMARTA QR-koder med Dinqr",
    icon: <MdOutlineQrCode size={50} />,
    redirect: "/support/borja-skapa",
    closeDown: true,
  },
  {
    title: "GDPR",
    underTitle: "Har du hur vi spara data",
    icon: <MdHealthAndSafety size={50} />,
    redirect: "/support/gdpr",
    closeDown: true,
  },
  {
    title: "Säkerhet",
    underTitle: "Hur vi hanterar din säkerhet",
    icon: <MdOutlineSafetyCheck size={50} />,
    redirect: "/support/sakerhet",
    closeDown: true,
  },
  {
    title: "Betalning",
    underTitle: "Hur vi hanterar dina betalningar",
    icon: <MdOutlinePayment size={50} />,
    redirect: "/support/betalning",
    closeDown: true,
  },
  {
    title: "F.A.Q",
    underTitle: "Se frågor och svar från tidigare kunder",
    icon: <MdChatBubbleOutline size={50} />,
    closeDown: false,
    openWebsite: process.env.REACT_APP_STATIC_STIE + "/faq",
  },
  {
    title: "Företag",
    underTitle:
      "Boka en kostnadsfri rådgivning om hur QR-koder kan förbättra effektiviteten i ert företag.",
    icon: <MdBusiness size={50} />,
    redirect: "/support/boka",
    closeDown: false,
  },
];

const Support = () => {
  const navigate = useNavigate();
  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gridTemplateColumns={"1fr"}
      gap={"20px"}
      width={"100%"}
      padding={"0px 0px 20px 0px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem>
          <WidgetWrapper
            className="backgroundGradient"
            child={
              <Flex color={"white"} width={"100%"} flexDirection={"column"}>
                <Text fontWeight={"bold"} fontSize={"22px"}>
                  Dinqr Support
                </Text>
                <Text>
                  Här finner du svar på de mest vanliga frågorna om dinqr. Du
                  kan utforska, lära dig mer och rapportera eventuella problem.
                  Om du inte hittar det du söker i vår lista, tveka inte att
                  kontakta oss på{" "}
                  <span style={{ fontWeight: "bold" }}>qr@dinqr.se</span> Vi är
                  här för att hjälpa dig!
                </Text>
              </Flex>
            }
          />
        </GridItem>
        <GridItem>
          <Flex color={"#292929"} width={"100%"} flexDirection={"column"}>
            <Text fontWeight={"bold"} fontSize={"22px"}>
              Behöver du hjälp? Vi finns här för dig.
            </Text>
            <Text>Kanske hittar du svaret i vår samling nedan.</Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex
            flexWrap={["nowrap", "nowrap", "wrap"]}
            flexDirection={["column", "column", "row"]}
            gap={5}
          >
            {questionSection.map((child) => {
              if (child?.closeDown) return;
              return (
                <WidgetWrapper
                  closeDown={child.closeDown}
                  extraTextComponoent={
                    <Text color={"black"} fontWeight={"bold"} fontSize={"12px"}>
                      Kommer snart
                    </Text>
                  }
                  className="supportItems"
                  child={
                    <Flex
                      onClick={() => {
                        if (child.redirect) {
                          navigate(child.redirect);
                        } else if (child.openWebsite) {
                          window.open(child.openWebsite, "_blank");
                        }
                      }}
                      cursor={"pointer"}
                      width={["100%", "100%", "200px"]}
                      height={("auto", "auto", "150px")}
                      flexDirection={"column"}
                      color={"#292929"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                    >
                      {child.icon}
                      <Text
                        fontWeight={"bold"}
                        fontSize={"16px"}
                        color={"#062C8E"}
                        textAlign={"center"}
                      >
                        {child.title}
                      </Text>
                      <Divider />
                      <Text fontSize={"12px"} textAlign={"center"}>
                        {child.underTitle}
                      </Text>
                    </Flex>
                  }
                />
              );
            })}
          </Flex>
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default Support;

/*onClick={() => navigate(child.redirect)} */
