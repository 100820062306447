import { auth } from "../../init/firebase";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  sendEmailVerification,
  updatePassword,
  getRedirectResult,
  verifyBeforeUpdateEmail,
  signInWithPopup,
} from "firebase/auth";
import { setUserClaimsBeta } from "../../calls";
import axios from "axios";
import { redirect } from "react-router-dom";

const signUpWithEmailAndPassword = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed up successfully
      const user = userCredential.user;
      // Send email verification
      await sendEmailVerification(user);
      try {
        axios.put(process.env.REACT_APP_LOGS, {
          targetUrl: window.location.href,
          action: "loggin-password",
          userID: user.uid ? user.uid : "",
        });
      } catch (error) {}
      return { status: 200, message: "Account is created" };
    })
    .catch((error) => {
      return {
        status: 400,
        error: "Något gick fel, de kan vara att kontot redan är registrerat.",
      };
    });
};

const resetPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent successfully
      try {
        axios.put(process.env.REACT_APP_LOGS, {
          targetUrl: window.location.href,
          action: "reset-password",
        });
      } catch (error) {}
      return {
        message: `Ett e-postmeddelande för återställning av lösenord har skickats till: ${email}`,
        status: 200,
      };
      // You can redirect the user to a password reset confirmation page or display a success message.
    })
    .catch((error) => {
      // Handle errors here
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(
        "Error sending password reset email:",
        errorCode,
        errorMessage
      );
      return { message: errorMessage, status: 400 };
      // You can display an error message to the user or handle the error in other ways.
    });
};

const logout = (user) => {
  signOut(auth)
    .then(() => {
      // User successfully logged out
      console.log("User logged out");
      // You can redirect the user to a logout confirmation page or perform other actions.
      try {
        axios.put(process.env.REACT_APP_LOGS, {
          targetUrl: window.location.href,
          action: "loggout-with-email-password",
          userID: user.uid ? user.uid : "",
        });
      } catch (error) {}
    })
    .catch((error) => {
      // Handle errors here
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error logging out:", errorCode, errorMessage);
      // You can display an error message to the user or handle the error in other ways.
    });
};

const loginWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    if (!user.emailVerified) {
      // If not verified, throw an error or handle it in a way appropriate for your application
      return { status: 400, message: "Inte veriferad" };
    }
    const idToken = await user.getIdToken();
    try {
      axios.put(process.env.REACT_APP_LOGS, {
        targetUrl: window.location.href,
        action: "loggin-with-email-password",
        userID: user.uid ? user.uid : "",
      });
    } catch (error) {}
    return { status: 200, user: user, idToken: idToken }; // Return the user object
  } catch (error) {
    // Handle errors here
    // You might want to throw the error here or handle it in other ways.
    return { status: 400, message: "Fel användare/lösenord" };
  }
};

const loginWithGooglePopUp = async () => {
  try {
    const userCred = await signInWithPopup(auth, new GoogleAuthProvider());
    if (userCred) {
      return { status: 200, redirect: "/dashboard" };
    } else {
      throw { status: 400, message: "Error signing in with Google" };
    }
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw { status: 400, message: "Error signing in with Google" };
  }
};

const loginWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw { status: 400, message: "Error signing in with Google" };
  }
};

const handleRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);

    if (result) {
      // User is signed in or has a credential, resolve with the desired path
      console.log(result, "good");
      return "/dashboard";
    } else {
      // No user signed in, resolve with null
      console.log(result, "bad");

      return null;
    }
  } catch (error) {
    console.error("Error handling redirect result", error);
    throw error;
  }
};

const changeFirebaseAuthPassword = async (email, password, newPassword) => {
  try {
    // Attempt to log in the user with their current credentials
    const loginResult = await loginWithEmailAndPassword(email, password);

    if (loginResult.status === 200) {
      // The user is successfully logged in, now update the password
      const user = loginResult.user;

      // Update the password
      await updatePassword(user, newPassword);

      try {
        axios.put(process.env.REACT_APP_LOGS, {
          targetUrl: window.location.href,
          action: "uppdate-password",
        });
      } catch (error) {}
      // Password updated successfully
      return {
        message: "Ditt lösenord är ändrats",
        status: 200,
      };
    } else {
      // Handle unsuccessful login
      return {
        error: "Ditt nuvarande lösenord stämde inte överense med de du angav",
        status: 402,
      };
    }
  } catch (error) {
    // Handle errors that might occur during the entire process
    return {
      error: "Något gick fel och ditt lösenord ändrades inte.",
      status: 400,
    };
  }
};

const changeFirebaseAuthEmail = async (email, password, newEmail) => {
  try {
    // Attempt to log in the user with their current credentials
    const loginResult = await loginWithEmailAndPassword(email, password);

    if (loginResult.status === 200) {
      // The user is successfully logged in, now update the password
      const user = loginResult.user;

      // Update the password
      await verifyBeforeUpdateEmail(user, newEmail);

      try {
        axios.put(process.env.REACT_APP_LOGS, {
          targetUrl: window.location.href,
          action: "change-email",
        });
      } catch (error) {}
      // Password updated successfully
      return {
        message: `Ett verification mail har skickat ${newEmail} för ändring av email.`,
        status: 200,
      };
    } else {
      // Handle unsuccessful login
      return {
        error: "Ditt lösenord stämde inte överense med de du angav",
        status: 402,
      };
    }
  } catch (error) {
    // Handle errors that might occur during the entire process
    return {
      error: "Något gick fel och ditt lösenord ändrades inte.",
      status: 400,
    };
  }
};

const sendNewEmailVerification = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    await sendEmailVerification(userCredential.user);

    return { status: 200 };
  } catch (error) {
    if (error.code === "auth/too-many-requests") {
      return {
        status: 429,
        error:
          "För många förfrågningar. Vänligen kolla din e-post eller försök igen om några sekunder. (10s - 20s) ",
      };
    } else {
      return { status: 400, error: error.message };
    }
  }
};

export {
  sendNewEmailVerification,
  loginWithGooglePopUp,
  changeFirebaseAuthEmail,
  changeFirebaseAuthPassword,
  signUpWithEmailAndPassword,
  resetPassword,
  logout,
  loginWithEmailAndPassword,
  loginWithGoogle,
  handleRedirectResult,
};
