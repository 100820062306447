import React, { useState, useContext } from "react";
import { UserContext } from "../context/index";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
} from "@chakra-ui/react";
import { toast } from "react-toastify";

import { sendTicket } from "../calls";

const TicketForm = ({ title }) => {
  const [state] = useContext(UserContext);

  const [formData, setFormData] = useState({
    subject: title ? title : "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await sendTicket(() => state.user.getIdToken(), formData);

    toast.success(
      "Vi har mottagit ditt meddelande och återkommer till dig så snart som möjligt."
    );

    // Reset form data after submission
    setFormData({
      userID: "",
      subject: "",
      description: "",
    });
  };

  return (
    <Box width={"100%"}>
      <Heading as="h2" size="lg" mb={4}>
        Kontakta oss
      </Heading>
      <Text mb="20px">
        {title
          ? "Boka en kostnadsfri rådgivningsmöte för att utforska hur er organisation kan dra maximal nytta av våra smarta QR-koder. Vår målsättning är att svara på ditt ärende och boka in ett möte så snabbt som möjligt. För att säkerställa en smidig hantering av dina ärenden kommer vi att återkoppla till din e-postadress."
          : " Vi är här för att hjälpa dig med alla dina frågor, förslag och behov. Vår målsättning är att svara på ditt ärende så snabbt och effektivt som möjligt. För att säkerställa en smidig hantering av dina ärenden kommer vi att återkoppla till din e-postadress."}
      </Text>
      <form onSubmit={handleSubmit}>
        <FormControl id="subject" mb={4}>
          <FormLabel>Ämne</FormLabel>
          <Input
            type="text"
            name="subject"
            value={title ? "Boka Möte" : formData.subject}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl id="description" mb={4}>
          <FormLabel>Beskrivning</FormLabel>
          <Textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={6}
            required
          />
        </FormControl>
        <Button type="submit" colorScheme="blue">
          Skicka
        </Button>
      </form>
    </Box>
  );
};

export default TicketForm;
