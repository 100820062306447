import {
  Flex,
  Text,
  Button,
  Divider,
  Center,
  Image,
  Link,
  Grid,
} from "@chakra-ui/react";
import GoogleSVG from "../../content/Google__G__Logo.svg";
import { loginWithGooglePopUp } from "./index";

import firebaseLogo from "../../content/firebase-icon.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const LoginWithSocial = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex color={"white"} margin={"20px"}>
        <Center w={["100%"]} h={["40px", "40px", "auto"]}>
          <Divider />
        </Center>
        <Text
          fontSize={"14px"}
          whiteSpace={"nowrap"}
          p={"0px 10px"}
          marginY={"auto"}
        >
          eller Logga in med
        </Text>
        <Center w={["100%"]} h={["40px", "40px", "auto"]}>
          <Divider />
        </Center>
      </Flex>
      <Grid gridTemplateColumns={"1fr"} alignItems={"center"}>
        <Button
          gridColumnStart={0}
          gridColumnEnd={2}
          gridRow={1}
          width={"100%"}
          fontSize={"14px"}
          fontWeight={"bold"}
          border={"1px solid black"}
          rounded={"lg"}
          bg={"white"}
          p={6}
          onClick={async () => {
            const userLogin = await loginWithGooglePopUp();
            if (userLogin.status === 200) {
              navigate(userLogin.redirect);
            } else {
              toast(userLogin.error);
            }
          }}
        >
          {" "}
          Logga In med Google
        </Button>

        <Image
          zIndex={2}
          marginLeft={"40px"}
          gridRow={1}
          gridColumnStart={0}
          gridColumnEnd={2}
          src={GoogleSVG}
        />
      </Grid>
      <Flex
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"20px"}
      >
        <Text
          color={"#718096"}
          padding={"0px 10px"}
          fontWeight={"bold"}
          fontSize={"10px"}
          textAlign={"center"}
        >
          Vänligen notera att inloggning via tredje parts appar kräver
          användning av en webbläsare som stöder OAuth 2.0. Att klicka på länkar
          genom sociala medieappar kanske inte fungerar korrekt. Öppna istället
          appen i en vanlig webbläsare på din telefon.
        </Text>
        <Link href="https://firebase.google.com/docs/auth" target="_blank">
          <Image w={"20px"} src={firebaseLogo}></Image>
        </Link>
      </Flex>
    </>
  );
};

export default LoginWithSocial;
