import { Text, Flex, Grid, Button } from "@chakra-ui/react";

import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { UserContext } from "../../../context/index.js";
import { useContext } from "react";
import { isPasswordAndEmail } from "../../../functions/isGoogleUser.js";

import { useNavigate } from "react-router-dom";

import { timeToDate } from "../../../functions/timeToDate.js";
import { getUserBillingPortalLink } from "../../../calls.js";

const Account = ({ userPlan }) => {
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Flex gap={4} flexDirection={["column", "column", "row"]}>
      <Grid gap={4} w={["100%", "100%", "50%"]} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"} gap={5} w={"100%"}>
              <Flex flexDirection={"column"} gap={1}>
                <Flex
                  fontWeight={"medium"}
                  color={"#5138EE"}
                  justifyContent={"right"}
                  flexDirection={"column"}
                  position={"relative"}
                >
                  <InputComponent
                    readOnly={true}
                    label={"Email"}
                    value={state.user}
                    keyValue={"email"}
                    type={"text"}
                  />
                  <Flex
                    fontSize={"14px"}
                    position={"absolute"}
                    textAlign={"right"}
                    bottom={-6}
                    right={0}
                    gap={2}
                  >
                    {isPasswordAndEmail(state.user) && (
                      <>
                        <Text
                          className="textHover"
                          onClick={() => navigate("updatera-email")}
                        >
                          Ändra email
                        </Text>
                        <Text>/</Text>
                        <Text
                          className="textHover"
                          onClick={() => navigate("updatera-losenord")}
                        >
                          Ändra lösenord
                        </Text>
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              <InputComponent
                readOnly={true}
                label={"Medlem sedan"}
                value={timeToDate(state.user.metadata.createdAt)}
                type={"text"}
              />
              <InputComponent
                readOnly={true}
                label={"User ID"}
                value={state.user.uid}
                type={"text"}
              />
              <InputComponent
                readOnly={true}
                label={"Kontonivå"}
                value={state.stripeRole ? state.stripeRole : "Gratis"}
                type={"text"}
              />
            </Flex>
          }
        />
      </Grid>
      <Grid w={["100%", "100%", "50%"]} marginBottom={"auto"}>
        {/* {userPlan && (
          <WidgetWrapper
            child={
              <Flex flexDirection={"column"} gap={5} w={"100%"}>
                <InputComponent
                  readOnly={true}
                  label={"Betalningsplan"}
                  value={state.stripeRole ? state.stripeRole : "Gratis"}
                  type={"text"}
                />
                <InputComponent
                  readOnly={true}
                  label={"Betalningsperiod"}
                  value={
                    userPlan.interval
                      ? userPlan.interval === "month"
                        ? "Månad"
                        : "År"
                      : ""
                  }
                  type={"text"}
                />
                <Flex marginLeft={"auto"}>
                  <Button
                    mt={4}
                    width={"100%"}
                    p={4}
                    bg={"#5138EE"}
                    color={"white"}
                    fontWeight={"bold"}
                    onClick={async () => {
                      try {
                        const { data } = await getUserBillingPortalLink(() =>
                          state.user.getIdToken()
                        );
                        window.location.href = data.url;
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Hantera dina planer
                  </Button>
                </Flex>
              </Flex>
            }
          ></WidgetWrapper>
        )} */}
      </Grid>
    </Flex>
  );
};
export default Account;
