import { Box, Grid, GridItem, Flex, Text, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../components/Widgets/WidgetWrapper";

import HighChart from "../../functions/toHighChart";

import { useEffect, useState, useContext } from "react";

import { UserContext } from "../../context/index.js";

import { formatNumber } from "../../functions/formatAmount";

import scanSVG from "../../content/icons/scan.svg";
import qrSVG from "../../content/icons/qr.svg";
import ScoreWidget from "../../components/Widgets/ScoreWidget";

import { countTotalEventsfromBigquery, getQRsByUserID } from "../../calls";
import { Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

const Dashboard = () => {
  const [state] = useContext(UserContext);

  const [totalScans, setTotalScans] = useState(0);
  const [loadingScans, setLoadingScans] = useState(true);
  const [totalQR, setTotalQR] = useState(0);
  const [loadingTotalQR, setLoadingTotalQR] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const totalScansData = await countTotalEventsfromBigquery(() =>
        state.user.getIdToken()
      );
      const totalQRData = await getQRsByUserID(() => state.user.getIdToken());

      setTotalScans(totalScansData ? totalScansData : 0);
      setTotalQR(totalQRData ? totalQRData : 0);
      setLoadingTotalQR(false);
      setLoadingScans(false);
      setLoading(false);
    };

    fetchData();
  }, [state]);

  if (loading) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"}></Spinner>
      </Flex>
    );
  }

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gridTemplateColumns={"1fr"}
      gap={"20px"}
      width={"100%"}
      padding={"0px 0px 20px 0px"}
      position={"relative"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem>
          <WidgetWrapper
            className={"backgroundGradient"}
            child={
              <Flex width={"100%"} flexDirection={"column"}>
                <Text fontSize={"22px"} fontWeight={"bold"} color={"white"}>
                  Välkommen till dinqr
                </Text>
                <Text fontSize={"12px"} color={"white"}>
                  Din svenska QR-leverantör! Skapa smarta QR-koder för att
                  optimera ditt företags synlighet och förenkla
                  kundinteraktionen. Låt oss hjälpa ditt företag att blomstra!
                </Text>
              </Flex>
            }
          />
        </GridItem>
        <GridItem>
          <Flex width={"100%"} gap={"20px"}>
            <Box flex={1}>
              <WidgetWrapper
                child={
                  !loadingScans ? (
                    <ScoreWidget
                      text={"Antal Scans"}
                      index={formatNumber(totalScans)}
                      icon={scanSVG}
                    />
                  ) : (
                    <Flex
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Spinner></Spinner>
                    </Flex>
                  )
                }
              />
            </Box>
            <Box flex={1}>
              <WidgetWrapper
                child={
                  !loadingTotalQR ? (
                    <ScoreWidget
                      text={"Antal QR-koder"}
                      index={totalQR + " st"}
                      icon={qrSVG}
                    />
                  ) : (
                    <Flex
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Spinner></Spinner>
                    </Flex>
                  )
                }
              />
            </Box>
          </Flex>
        </GridItem>
        <GridItem w={"100%"}>
          <WidgetWrapper
            callToAction={true}
            payedFeature={false}
            child={
              <Flex w={"100%"} h={"100%"} flexDirection={"column"} gap={3}>
                <Flex alignItems={"center"}>
                  <Text fontWeight={"bold"} fontSize={"14px"}>
                    Veckans scans
                  </Text>
                  <Box marginLeft={"auto"}>
                    <Tooltip
                      label={
                        "Här kan du se veckans scans på alla dina smarta QR-koder."
                      }
                      fontSize="xs"
                      p={"10px"}
                      bg="#292929"
                    >
                      <InfoIcon color={"#292929"} />
                    </Tooltip>
                  </Box>
                </Flex>

                <HighChart
                  removeLegend={true}
                  queryOptions={{
                    interval: "DAY",
                    timespan: 7,
                  }}
                />
              </Flex>
            }
          />
        </GridItem>
      </Grid>
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem w={"100%"}></GridItem>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
