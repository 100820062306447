import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import RouterComponents from "./Router";
import { ChakraProvider } from "@chakra-ui/react";
import { UserProvider } from "./context/index.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <ChakraProvider>
      <RouterComponents />
    </ChakraProvider>
  </UserProvider>
);
