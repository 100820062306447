import { Grid, Flex, Text, Button, Checkbox } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";

import { UserContext } from "../../../context/index.js";

import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { removeAccount } from "../../../calls";

const DeleteAccount = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      width={["100%", "100%", "70%"]}
      gap={"20px"}
    >
      <WidgetWrapper
        child={
          <Flex flexDirection={"column"} gap={3}>
            <Text fontSize={"20px"}>Vill du ta bort ditt konto?</Text>
            <Text fontSize={"14px"}>
              När du tar bort ditt konto kommer du inte längre kunna få åtkomst
              till dinqr. Trots detta kommer dina QR-koder fortfarande vara
              aktiva men vi förbehåller oss rätten att ta bort dem efter en viss
              tid. Vi rekommenderar därför att du tar bort dem från den fysiska
              platsen om du besluter att inte vilja använda dinqr längre.
            </Text>
            <Flex>
              <Checkbox
                isChecked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              >
                Jag godkänner att mitt konto tas bort.
              </Checkbox>
              <Button
                color={"white"}
                bg={"#ee3838"}
                marginLeft={"auto"}
                isDisabled={!isCheckboxChecked}
                onClick={async () => {
                  await removeAccount(() => state.user.getIdToken());
                  navigate("/");
                }}
              >
                Ta bort ditt konto
              </Button>
            </Flex>
          </Flex>
        }
      ></WidgetWrapper>
    </Grid>
  );
};

export default DeleteAccount;
