import { Flex, Input } from "@chakra-ui/react";
import { InputComponent, InputSelector } from "../Widgets/InputComponent";

const QrStyle = ({ setState, value }) => {
  return (
    <Flex gap={3} flexWrap={"wrap"}>
      <Flex flexDirection={"column"}>
        <Flex gap={3} flexWrap={"wrap"}>
          <InputComponent
            placeholder={"#FFFFF"}
            label={"Bakgrundsfärg"}
            setState={setState}
            value={value}
            keyValue={"background"}
          />
          <Input
            marginTop="auto"
            type="color"
            value={value.background}
            w={"70px"}
            onChange={(e) => setState({ ...value, background: e.target.value })}
          />
        </Flex>
        <Flex gap={3} flexWrap={"wrap"}>
          <InputComponent
            placeholder={"#FFFFF"}
            label={"Mönsterfärg"}
            setState={setState}
            value={value}
            keyValue={"color"}
          />
          <Input
            marginTop="auto"
            type="color"
            value={value.color}
            w={"70px"}
            onChange={(e) => setState({ ...value, color: e.target.value })}
          />
        </Flex>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex gap={3}>
          <InputSelector
            toolTip={true}
            toolTipText={
              "EC-skydd är en teknik i QR-koder som gör dem mer tåliga. Genom att lägga till extra pixlar kan koden fortfarande läsas även om den är smutsig eller skadad."
            }
            label={"EC-skydd"}
            setState={setState}
            value={value}
            keyValue={"ecLevel"}
            options={[
              { text: "Liten", value: "L" },
              { text: "Mellan", value: "M" },
              { text: "Stor", value: "H" },
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default QrStyle;
