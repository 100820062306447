import { Box, Flex, Grid, Button, Spinner, Text } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { useContext, useEffect, useState } from "react";
import { changeFirebaseAuthPassword } from "../../Login/index.js";
import { UserContext } from "../../../context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { isGoogleUser } from "../../../functions/isGoogleUser";
import { unlink } from "firebase/auth";

const ChangePassword = () => {
  const [state] = useContext(UserContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordOK, setPasswordOK] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      password === repeatPassword &&
      password.length >= 8 &&
      repeatPassword.length >= 8 &&
      oldPassword.length >= 8 &&
      state.user.email
    ) {
      setPasswordOK(true);
    } else {
      setPasswordOK(false);
    }
  }, [password, repeatPassword, oldPassword, state.user.email]);

  return (
    <Grid width={["100%", "100%", "600px"]} gap={5}>
      {state.user && isGoogleUser(state.user) ? (
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"}>
              <Text color={"#292929"} fontWeight={"semibold"}>
                Avlänkning från Google-konto{" "}
              </Text>
              <Text color={"#292929"}>
                Du har ett Google-konto kopplat till ditt användarkonto. För att
                byta lösenord måste du först välja att avlänka dig från Google.
              </Text>
              <Box>
                <Button
                  marginTop={"20px"}
                  bg={"#5138EE"}
                  color={"white"}
                  onClick={async () => {
                    await unlink(state.user, "google.com");
                    toast.success(
                      "Du avlänkade ditt Google Konto och kan nu lösenord."
                    );
                    navigate("updatera-losenord");
                  }}
                >
                  Avlänka Google konto
                </Button>
              </Box>
            </Flex>
          }
        ></WidgetWrapper>
      ) : (
        <WidgetWrapper
          child={
            <form
              style={{ width: "100%" }}
              onSubmit={async (e) => {
                e.preventDefault(e);
                if (password === oldPassword) {
                  toast.error("Ditt gamla lösenord är samma som ditt nya.");
                  return;
                }
                setLoading(true);
                const response = await changeFirebaseAuthPassword(
                  state.user.email,
                  oldPassword,
                  password
                );
                if (response.status !== 200) {
                  toast.error(response.error);
                } else {
                  toast.success(response.message);
                  navigate("");
                }
                setLoading(false);
              }}
            >
              <Flex flexDirection={"column"} width={"100%"} gap={4}>
                <InputComponent
                  toolTip={true}
                  toolTipText={"Skriv ditt nya lösenord du vill byta till."}
                  placeholder={"min. 8 karaktärer"}
                  label={"Nya Lösenordet"}
                  setState={setPassword}
                  value={password}
                  type={"password"}
                  showPassword={true}
                />
                <InputComponent
                  toolTip={true}
                  toolTipText={"Upprepa ditt nya lösenord du vill byta till."}
                  placeholder={"min. 8 karaktärer"}
                  label={"Upprepa ditt nya lösenordet"}
                  setState={setRepeatPassword}
                  value={repeatPassword}
                  type={"password"}
                  showPassword={true}
                />
                <InputComponent
                  toolTip={true}
                  toolTipText={"Ditt nuvarande lösenord."}
                  placeholder={"Nuvarande lösenordet"}
                  label={"Nuvarande lösenordet"}
                  setState={setOldPassword}
                  value={oldPassword}
                  type={"password"}
                  showPassword={true}
                />
                <Box>
                  <Button
                    color={"white"}
                    fontWeight={"bold"}
                    isDisabled={!loading && passwordOK ? false : true}
                    bg={"#5138EE"}
                    type="submit"
                  >
                    {loading ? <Spinner /> : "Bytt lösenord "}
                  </Button>
                </Box>
              </Flex>
            </form>
          }
        ></WidgetWrapper>
      )}
    </Grid>
  );
};

export default ChangePassword;
