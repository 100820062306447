import { Box, Grid, Flex, Button, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../../components/Widgets/InputComponent";

import { useContext } from "react";
import DesignConstructor from "../../../../components/DesignQR";
import { UserContext } from "../../../../context/index.js";

import SelectionWidget from "../../../../components/Widgets/SelectionWidget";

import { editQRCode as editQRCodeCall } from "../../../../calls";

import { areAllValuesSet } from "../../../../functions/areAllValuesSet";

import { useNavigate } from "react-router-dom";
import QrGenerator from "../../../../components/QrGenerator.jsx";
import { toast } from "react-toastify";
import { ensureProtocol } from "../../../../functions/ensureProtocolUrl.js";

const EditQR = ({ qrSettings, setqrSettings, loadImage, setLoadImage }) => {
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Flex flexDirection={"column"} padding={"0px 0px 20px 0px"}>
      {qrSettings ? (
        <Grid
          display={["flex", "flex", "grid"]}
          flexDirection={"column"}
          color={"white"}
          gridTemplateColumns={"1fr 1fr "}
          gap={"20px"}
        >
          <Grid gap={"20px"}>
            <WidgetWrapper
              blockForReadersOnly={true}
              callToAction={true}
              child={
                <Box w={"100%"}>
                  <InputComponent
                    toolTip={true}
                    toolTipText={
                      "Här har du möjligheten att ändra din QR-kods namn för att underlätta sökningen i ditt system."
                    }
                    placeholder={"Bröllops Inbjudningar"}
                    label={"QR Namn"}
                    setState={setqrSettings}
                    value={qrSettings}
                    keyValue={"qrName"}
                    type={"text"}
                  />
                  <InputComponent
                    toolTip={true}
                    toolTipText={
                      "Här har du möjligheten att ändra länken som är destinationen dit din QR-kod kommer att leda till efter att den har scannats."
                    }
                    placeholder={"https://website.com"}
                    label={"Länk"}
                    setState={setqrSettings}
                    value={qrSettings}
                    keyValue={"targetUrl"}
                    type={"url"}
                  />
                  <SelectionWidget
                    toolTip={true}
                    toolTipText={
                      "Här har du möjlighet att lägga till eller ta bort  taggar för att göra det enklare att sortera och hantera dina QR-koder."
                    }
                    payedFeature={false}
                    label={"Taggar"}
                    qrSettings={qrSettings}
                    setqrSettings={setqrSettings}
                  />
                </Box>
              }
            />
            <WidgetWrapper
              callToAction={true}
              payedFeature={false}
              child={
                <DesignConstructor
                  setState={setqrSettings}
                  value={qrSettings}
                  setLoadImage={setLoadImage}
                />
              }
            />
          </Grid>

          <Grid gap={"20px"} marginBottom={"auto"}>
            <WidgetWrapper
              blockForReadersOnly={true}
              center={true}
              child={
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  position={"relative"}
                  width={"100%"}
                >
                  {loadImage ? (
                    <Flex alignItems={"center"} height={"230px"}>
                      <Spinner></Spinner>
                    </Flex>
                  ) : (
                    <QrGenerator
                      loadImage={loadImage}
                      qrSettings={qrSettings}
                      setqrSettings={setqrSettings}
                    />
                  )}
                </Flex>
              }
            />
            <Flex flexDirection={"column"} gap={"20px"}>
              <Button
                w={"full"}
                p={8}
                bg={"#5138EE"}
                fontSize={"18px"}
                color={"white"}
                fontWeight={"bold"}
                isDisabled={!areAllValuesSet(qrSettings)}
                onClick={async () => {
                  if (!areAllValuesSet(qrSettings)) return;

                  const editCodeResponse = await editQRCodeCall(
                    () => state.user.getIdToken(),
                    {
                      ...qrSettings,
                      targetUrl: ensureProtocol(qrSettings.targetUrl),
                    }
                  );
                  if (editCodeResponse.status === 200) {
                    setqrSettings(editCodeResponse.data.data);
                    navigate("");
                  } else {
                    toast.error(
                      "Något gick fel och QR-koden updaterades inte."
                    );
                  }
                }}
              >
                Redigera QR-kod
              </Button>
            </Flex>
          </Grid>
        </Grid>
      ) : (
        <Flex justifyContent={"center"} alignItems={"center"} height={"100%"}>
          <Spinner size={"xl"} color="#062C8E"></Spinner>
        </Flex>
      )}
    </Flex>
  );
};

export default EditQR;
